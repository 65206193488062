export const URL_DASHBOARD = "/dashboard";
export const URL_LOGIN = "/login";
export const URL_PAGES = "/pages";
export const URL_FORGOT_PASSWORD = "/forgot-password";
export const URL_CHANGE_PASSWORD = "/change-password";
export const URL_STATIC = "/static-page";
export const URL_HELP_DESK = "/help-desk";
export const URL_REFERRAL_REWARDS = "/referral-rewards";
export const URL_PROFILE = "/profile";
export const URL_PRICE = "/price";
export const URL_PAYOUT = "/payout";
export const URL_GENERAL_SETTING = "/general-setting";
export const URL_CLIENT_USER = "/seeker-user";
export const URL_ANQITE_USER = "/anqite-user";
export const URL_USERMODULE = "/user-module";
export const URL_CLIENT_VERIFIED_USER = "/seeker-verified-user";
export const URL_CLIENT_REGISTERED_USER = "/seeker-registered-user";
export const URL_REGISTERED_ANQITE_USER = "/registered-anqite-user";
export const URL_VERIFIED_ANQITE_USER = "/verified-anqite-user";
export const URL_FEATURED_ANQITE = "/featured-anqite";
export const URL_BADGE = "/badge";
export const URL_BANNER = "/banner";
export const URL_EVENTS = "/events";
export const URL_USER_REPORTED = "/user-reported";
export const URL_DEFAULT_POINTS = "/points";
export const URL_SERVICE = "/service";
export const URL_GENERATE_CODE = "/generate-code";
export const URL_PENDING_USER = "/pending_user";
export const URL_APPROVED_USER = "/approved-user";
export const URL_PROFESION = "/profession-listing";
export const URL_SERVICE_TYPE = "/service-type";
export const URL_BOOKINGS = "/bookings";
export const URL_SERVICE_NAME = "/service-name";
export const URL_ADDITIONAL_CATEGORY = "/additional-category";
export const URL_SERVICE_LISTING = "/service-listing";
export const URL_FAQ = "/faq";
export const URL_Sub_Admin = "/sub-admin";
export const URL_SERVICE_LISTING_FREELANCER = "/service-anqite";
export const URL_SERVICE_LISTING1_FREELANCER = "/service-listing-anqite";
export const URL_SERVICE_AVAILABILITY = "/service-listing-availability";
export const URL_CANCELLATION_POLICY = "/cancellation-policy";
export const URL_TEMPLATE = "/template";
export const URL_SUBSCRIPTION = "/subscription";
export const URL_SUBSCRIPTION_PLAN = "/subscription-plan";
export const URL_ADD_ONS = "/add-ons";
export const URL_SUGGESTIONS = "/suggestion";
export const URL_EMAIL = "/email";
export const URL_NOTIFICATION = "/notification";
export const URL_LANGUAGE = "/language";
export const URL_RESET_PASSWORD = "/reset-password/:token";
export const URL_HEALTH_SAFETY = "/health-safety";
export const URL_ABOUT_US = "/about-us";
export const URL_TERMS = "/terms";
export const URL_PRIVACY = "/privacy";
export const URL_HOW_IT_WORKS = "/how-it-works";
export const URL_CONTACT_US = "/contact-us";
export const URL_CANCELLATION = "/cancellation";
export const URL_ACCOUNT_LINK_SUCCESS = "/account-link-success";
export const URL_ACCOUNT_LINK_ERROR = "/account-link-error";
export const URL_GETTING_STARTED = "/getting-started";
export const URL_REFERRAL_EARNING = "/referral-earning";
export const URL_ANQA_SUBSCRIPTION_PLAN = "/anqa-subscription-plan";
export const URL_ANQA_POINTS = "/anqa-points";
export const URL_KNOW_MORE_ABOUT_PAYOUT = "/know-more-about-payout";
export const URL_TRANSACTIONS = "/transactions";
export const URL_TRANSACTIONS_ANQITE = "/transactions-anqite";
export const URL_TRANSACTIONS_SEEKER = "/transactions-seeker";
