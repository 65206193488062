import React from "react";
import LazyLoader from "@loadable/component";

import {
	URL_DASHBOARD,
	URL_CHANGE_PASSWORD,
	URL_STATIC,
	URL_BADGE,
	URL_DEFAULT_POINTS,
	URL_SERVICE_TYPE,
	URL_PROFILE,
	URL_SERVICE,
	URL_BANNER,
	URL_PROFESION,
	URL_SERVICE_LISTING,
	URL_LANGUAGE,
	URL_SERVICE_LISTING_FREELANCER,
	URL_CLIENT_VERIFIED_USER,
	URL_ANQITE_USER,
	URL_REGISTERED_ANQITE_USER,
	URL_CLIENT_REGISTERED_USER,
	URL_VERIFIED_ANQITE_USER,
	URL_CLIENT_USER,
	URL_SERVICE_NAME,
	URL_CANCELLATION_POLICY,
	URL_PRICE,
	URL_FEATURED_ANQITE,
	URL_ADDITIONAL_CATEGORY,
	URL_NOTIFICATION,
	URL_TEMPLATE,
	URL_EMAIL,
	URL_SUBSCRIPTION,
	URL_SUBSCRIPTION_PLAN,
	URL_ADD_ONS,
	URL_PAYOUT,
	URL_EVENTS,
	URL_HELP_DESK,
	URL_SUGGESTIONS,
	URL_REFERRAL_REWARDS,
	URL_BOOKINGS,
	URL_TRANSACTIONS_SEEKER,
	URL_TRANSACTIONS_ANQITE,
	URL_TRANSACTIONS,
	URL_USER_REPORTED,
} from "Helpers/Paths";

import {
	BarChartOutlined,
	StrikethroughOutlined,
	UserOutlined,
	SettingOutlined,
	BoldOutlined,
	SolutionOutlined,
	AuditOutlined,
	CloseSquareOutlined,
	FontSizeOutlined,
	BankOutlined,
	CalendarOutlined,
	WalletOutlined,
	IssuesCloseOutlined,
	UsergroupDeleteOutlined,
} from "@ant-design/icons";

export const adminRoute = {
	path: URL_USER_REPORTED,
	exact: true,
	component: LazyLoader(() => import("Components/ReportedUser/ReportedUser")),
	sidebar: {
		name: "Reported User",
		icon: <UsergroupDeleteOutlined />,
	},
};

export const otherRoute = [
	{
		path: "/",
		exact: true,
		component: LazyLoader(() => import("Components/Dashboard/Dashboard")),
	},
	{
		path: URL_DASHBOARD,
		exact: true,
		component: LazyLoader(() => import("Components/Dashboard/Dashboard")),
		sidebar: {
			name: "Dashboard",
			icon: <BarChartOutlined />,
		},
	},
	{
		path: URL_CLIENT_USER,
		exact: true,

		sidebar: {
			name: "Seeker User",
			icon: <UserOutlined />,
			nested: [
				{
					name: "Registered User",
					path: URL_CLIENT_REGISTERED_USER,
					component: LazyLoader(() =>
						import("Components/ClientRegisteredUser/ClientRegisteredUser")
					),
				},
				{
					name: "Verified User",
					path: URL_CLIENT_VERIFIED_USER,
					component: LazyLoader(() =>
						import("Components/ClientVerifiedUser/ClientVerifiedUser")
					),
				},
			],
		},
	},
	{
		path: URL_ANQITE_USER,
		exact: true,
		sidebar: {
			name: "Anqite User",
			icon: <UserOutlined />,
			nested: [
				{
					name: "Registered User",
					path: URL_REGISTERED_ANQITE_USER,
					component: LazyLoader(() =>
						import("Components/RegisteredAnqiteUser/RegisteredAnqiteUser")
					),
				},
				{
					name: "Verified User",
					path: URL_VERIFIED_ANQITE_USER,
					component: LazyLoader(() =>
						import("Components/VerifiedAnqiteUser/VerifiedAnqiteUser")
					),
				},
				{
					name: "Featured Anqite",
					path: URL_FEATURED_ANQITE,
					component: LazyLoader(() =>
						import("Components/FeaturedAnqite/FeaturedAnqite")
					),
				},
			],
		},
	},
	{
		path: URL_TRANSACTIONS,
		exact: true,
		sidebar: {
			name: "Transactions Log",
			icon: <WalletOutlined />,
			nested: [
				{
					name: "Anqite",
					path: URL_TRANSACTIONS_ANQITE,
					component: LazyLoader(() =>
						import("Components/TransactionsAnqite/TransactionsAnqite")
					),
				},
				{
					name: "Seeker",
					path: URL_TRANSACTIONS_SEEKER,
					component: LazyLoader(() =>
						import("Components/TransactionsAnqite/TransactionsSeeker")
					),
				},
			],
		},
	},
	{
		path: URL_EVENTS,
		exact: true,
		component: LazyLoader(() => import("Components/Events/Events")),
		sidebar: {
			name: "Events",
			icon: <CalendarOutlined />,
		},
	},
	{
		path: URL_BANNER,
		exact: true,
		component: LazyLoader(() => import("Components/Banner/Banner")),
		sidebar: {
			name: "Banner",
			icon: <BoldOutlined />,
		},
	},
	{
		path: URL_BADGE,
		exact: true,
		component: LazyLoader(() => import("Components/Badges/Badges")),
		sidebar: {
			name: "Badge",
			icon: <BoldOutlined />,
		},
	},

	{
		path: URL_SERVICE_LISTING,
		exact: true,
		sidebar: {
			name: "Services",
			icon: <StrikethroughOutlined />,
			nested: [
				{
					name: "Service Category",
					path: URL_SERVICE,
					component: LazyLoader(() => import("Components/Services/Services")),
				},
				{
					name: "Service Type",
					path: URL_SERVICE_TYPE,
					component: LazyLoader(() =>
						import("Components/ServiceType/ServiceTypeListing")
					),
				},
				{
					name: "Service Name",
					path: URL_SERVICE_NAME,
					component: LazyLoader(() =>
						import("Components/ServiceSubType/ServiceSubTypeListing")
					),
				},
				{
					name: "Additional Service Category",
					path: URL_ADDITIONAL_CATEGORY,
					component: LazyLoader(() =>
						import(
							"Components/AdditionalServiceCategory/AdditionalServiceCategory"
						)
					),
				},
				{
					name: "Bookings",
					path: URL_BOOKINGS,
					component: LazyLoader(() => import("Components/Bookings/Bookings")),
				},
			],
		},
	},
	{
		path: URL_SERVICE_LISTING_FREELANCER,
		exact: true,
		component: LazyLoader(() =>
			import("Components/FerlancerService/ServiceListing")
		),
		sidebar: {
			name: "Anqite Services",
			icon: <AuditOutlined />,
		},
	},
	{
		path: URL_CANCELLATION_POLICY,
		exact: true,
		component: LazyLoader(() =>
			import("Components/CancellationPolicy/CancellationPolicy")
		),
		sidebar: {
			name: "Cancellation Policy",
			icon: <CloseSquareOutlined />,
		},
	},
	{
		path: URL_TEMPLATE,
		exact: true,
		sidebar: {
			name: "Templates",
			icon: <FontSizeOutlined />,
			nested: [
				{
					name: "Email",
					path: URL_EMAIL,
					component: LazyLoader(() => import("Components/Email/Email")),
				},
				{
					name: "Notification",
					path: URL_NOTIFICATION,
					component: LazyLoader(() =>
						import("Components/Notification/Notification")
					),
				},
			],
		},
	},
	{
		path: URL_ADD_ONS,
		exact: true,
		component: LazyLoader(() => import("Components/Addons/Addons")),
		sidebar: {
			name: "Add-ons",
			icon: <BankOutlined />,
		},
	},
	{
		path: "/availabilityCount",
		exact: true,
		component: LazyLoader(() => import("Components/FerlancerService/Avablity")),
	},
	{
		path: "/logs",
		exact: true,
		component: LazyLoader(() => import("Components/Approved User/Logs")),
	},
	{
		path: "/wallet-logs",
		exact: true,
		component: LazyLoader(() => import("Components/ClientVerifiedUser/Logs")),
	},
	{
		path: "/list-wallet-logs",
		exact: true,
		component: LazyLoader(() => import("Components/VerifiedAnqiteUser/Logs")),
	},
	{
		path: "/generatelogs",
		exact: true,
		component: LazyLoader(() =>
			import("Components/Generate Code/GenerateLogs")
		),
	},
	{
		path: "/serviceLogs",
		exact: true,
		component: LazyLoader(() =>
			import("Components/FerlancerService/ServiceLogs")
		),
	},
	{
		path: "/pendingApprovalLogs",
		exact: true,
		component: LazyLoader(() =>
			import("Components/PendingApproval/PendingApprovalLogs")
		),
	},
	{
		path: "/user-listing",
		exact: true,
		component: LazyLoader(() => import("Components/Badges/UserListing")),
	},

	{
		path: URL_PROFESION,
		exact: true,
		component: LazyLoader(() =>
			import("Components/Proffession/ProffesionListing")
		),
		sidebar: {
			name: "Profession",
			icon: <SolutionOutlined />,
		},
	},

	{
		path: "/change-password",
		exact: true,
		component: LazyLoader(() =>
			import("Components/ChangePassword/ChangePassword")
		),
	},

	{
		path: URL_CHANGE_PASSWORD,
		exact: true,
		sidebar: {
			name: "Settings",
			icon: <SettingOutlined />,
			nested: [
				{
					name: "Profile",
					path: URL_PROFILE,
					component: LazyLoader(() => import("Components/Profile/Profile")),
				},
				{
					name: "Price Conversion",
					path: URL_PRICE,
					component: LazyLoader(() => import("Components/Price/Price")),
				},
				{
					name: "Payout",
					path: URL_PAYOUT,
					component: LazyLoader(() =>
						import("Components/MinimumPayout/MinimumPayout")
					),
				},

				{
					name: "Static Content",
					path: URL_STATIC,
					component: LazyLoader(() => import("Components/Static/Static")),
				},

				{
					name: "Languages",
					path: URL_LANGUAGE,
					component: LazyLoader(() =>
						import("Components/Languages/LanguageListing")
					),
				},
			],
		},
	},
];

const routesList = [adminRoute, ...otherRoute];
export default routesList;
